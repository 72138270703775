var _ = require('lodash');
//import GyroNorm from './gyronorm.complete.min.js'
//GyroNorm = require ('./gyronorm.complete.min.js')
// https://github.com/dorukeker/gyronorm.js

AppSensorListener = {
    logit: false,
    testing: false, // use random data rather than device sensor data
    initialized: false,
    sampleCounter:0,

    pushDataInterval: 2000, //msec between pushing data

    dataPointIntervalMsec: 250, //msec between data points to capture
    bufferLengthSeconds: 1,
    dataBuffer:[],
    sendDataIntervalMsec: 1000, // msec between when to send data to server

    init: _.once(()=> {
        //if (AppSensorListener.initialized) return;
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
            DeviceMotionEvent.requestPermission()
                .then(permissionState => {
                    if (permissionState === 'granted') {
                        AppSensorListener.initialized = true;
                        window.addEventListener('devicemotion', event => {
                            if (this.logit) console.log(event.acceleration.x + ' m/s2');
                        });
                        if (this.logit) console.log('Initialized AppSensorListener');
                        setTimeout(()=>AppSensorListener.start(),1000);
                    }
                })
                .catch(console.error);
        } else {
            // handle regular non iOS 13+ devices
            AppSensorListener.initialized = true;
            window.addEventListener('devicemotion', event => {
                if (this.logit) console.log(event.acceleration.x + ' m/s2');
            });
            if (this.logit) console.log('Initialized AppSensorListener');
            setTimeout(()=>AppSensorListener.start(),1000);
        }
    }),
    test: function() {
        if (typeof DeviceMotionEvent.requestPermission === 'function') {
            DeviceMotionEvent.requestPermission()
                .then(permissionState => {
                    if (permissionState === 'granted') {
                        window.addEventListener('devicemotion', () => {});
                    }
                })
                .catch(console.error);
        } else {
            // handle regular non iOS 13+ devices
        }
        window.addEventListener('devicemotion', event => {
            if (this.logit) console.log(event.acceleration.x + ' m/s2');
        });
    },
    handleMotionEvent: function(event) {
        // Store all the data from the x,y,z, roll/pitch/yaw motion detectors into arrays, along with integrated position data
        let val;
        let testing = AppSensorListener.testing;
        // beta has large variation for breathing, also showing heart rate at higher frequency
        // xg has smaller but probably usable variation for breathing
        // in unity, beta has large heart variation and not much else
        let dimOne = 'xg';
        let dimTwo = 'beta';
        let dimThree = 'yg';
        let dimFour = 'zg';
        let currentData = [];
        currentData[0] = AppAudio.now()*1000; // current time at sample in msec, for later correction/interpolation
        currentData[1] = AppSensorListener.get(event,dimOne,testing);
        currentData[2] = AppSensorListener.get(event,dimTwo,testing);
        currentData[3] = AppSensorListener.get(event,dimThree,testing);
        currentData[4] = AppSensorListener.get(event,dimFour,testing);
        AppSensorListener.dataBuffer.push(currentData);
        AppSensorListener.sampleCounter++;
    },
    get: function(event,type,testing) {
        if (AppSensorListener.testing) return Math.random();
        var result;
        switch(type) {

            case "x": result = event.acceleration.x;break;
            case "y": result =  event.acceleration.y;break;
            case "z": result =  event.acceleration.z;break;

            case "xg": result = event.accelerationIncludingGravity.x;break;
            case "yg": result =  event.accelerationIncludingGravity.y;break;
            case "zg": result =  event.accelerationIncludingGravity.z;break;

            case "alpha": result =  event.rotationRate.alpha;break;
            case "beta": result =  event.rotationRate.beta;break;
            case "gamma": result =  event.rotationRate.gamma;break;

            default: console.log('Invalid Motion.get');
        }
        //result = Math.round(result * 100000)
        return result;
    },
    start: function() {
        AppSensorListener.initialized = true;
        AppSensorListener.sampleRate = 1000/AppSensorListener.dataPointIntervalMsec; //msec between updating chart
        AppSensorListener.bufferLength = AppSensorListener.bufferLengthSeconds * AppSensorListener.sampleRate;

        if (!AppSensorListener.testing) {
            if (typeof DeviceMotionEvent.requestPermission === 'function') {
                DeviceMotionEvent.requestPermission()
                    .then(permissionState => {
                        if (permissionState === 'granted') {
                            window.addEventListener("devicemotion", AppSensorListener.handleMotionEvent, true); // at semi-periodic rate, process device motion
                        }
                    })
                    .catch(console.error);
            } else {
                window.addEventListener("devicemotion", AppSensorListener.handleMotionEvent, true); // at semi-periodic rate, process device motion
            }
        }
        else {
            // periodically update with random testing data
            AppSensorListener.testInterval = setInterval(function(){
                    AppSensorListener.handleMotionEvent();
                },
                AppSensorListener.dataPointIntervalMsec);
        }
        AppSensorListener.sendDataInterval = Tone.Transport.scheduleRepeat(()=>{AppSensorListener.sendData()},"1" ); //Set Interval
    },
    stop: function() {
        window.removeEventListener("devicemotion",AppSensorListener.handleMotionEvent);
        clearInterval(AppSensorListener.testInterval);
        clearInterval(AppSensorListener.sendDataInterval);
        },
    sendData: function() {
        AppMsg.sendData(AppSensorListener.dataBuffer);
        let data;
        data = AppSensorListener.dataBuffer;
        if (AppSensorListener.logit) console.table(data);
        // TODO: is it possible that if this is called while handleMotionEvent is in process, it causes a conflict or error?
        AppSensorListener.dataBuffer = [];
    }
};
